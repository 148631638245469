<template>
  <div class="white">
    <move-email
      v-model="showMoveModal"
      :email="emailContent"
      @close="close"
      @update:email="onEmailUpdate"
    />
    <v-row class="rows email-data px-6" :data-email-id="emailContent.id" no-gutters>
      <v-col :cols="inlineAttachments.length ? 5 : 6">
        <div>
          <v-label>
            Subject:
          </v-label>
          <span class="cursor-text" v-text="emailContent.subject" />
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label>
            From:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.from_address) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label>
            To:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.to_addresses) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label v-if="emailContent.cc_addresses">
            CC:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.cc_addresses) }}</span>
        </div>
        <div :class="{flexible: !showMoreActive}">
          <v-label v-if="emailContent.bcc_addresses">
            BCC:
          </v-label>
          <span :class="{truncated: !showMoreActive}">{{ formatEmailList(emailContent.bcc_addresses) }}</span>
        </div>
        <div class="show-more" @click="toggleShowMore">
          {{ showMoreText }}
        </div>
      </v-col>
      <v-col :cols="inlineAttachments.length ? 3 : 4">
        <v-label v-if="realAttachments.length || emailContent.has_pending_attachments">
          Attachments:
        </v-label>

        <attachment-links-list v-if="realAttachments.length" :attachments="realAttachments" color-pdf>
          <template v-slot:attachment-append="{ attachment }">
            <v-menu
              v-if="orderHasPO || orderHasQuotes"
              v-model="attachmentMenus[attachment.id]"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props: menu }">
                <qtm-icon-btn
                  icon="mdi-chevron-down"
                  size="small"
                  v-bind="menu"
                />
              </template>
              <v-list class="qtm-border">
                <v-list-item v-if="orderHasPO" @click="copyToPO(internalOrder.pos[0], attachment)">
                  <v-list-item-title>Copy to PO</v-list-item-title>
                </v-list-item>

                <v-menu v-if="orderHasQuotes" open-on-hover :open-delay="0" location="end">
                  <template v-slot:activator="{ props: submenu }">
                    <v-list-item v-bind="submenu" @click="attachmentMenus[attachment.id] = true">
                      <v-list-item-title>
                        Copy to Quote
                        <v-icon class="ml-1" size="small" color="mid-light-grey">
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="qtm-border">
                    <v-list-item
                      v-for="quote in internalOrder.quotes"
                      :key="quote.id"
                      @click="copyToQuote(quote, attachment)"
                    >
                      <v-list-item-title>{{ quote.vendor?.name ?? 'No Supplier' }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list>
            </v-menu>
          </template>
        </attachment-links-list>

        <v-tooltip v-if="emailContent.has_pending_attachments" location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" color="red">
              mdi-alert
            </v-icon>
            <span>Pending</span>
          </template>
          <span>This email has pending attachments.</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="inlineAttachments.length" cols="2">
        <v-btn color="black" variant="text" size="small" @click="showInlineAttachments = !showInlineAttachments">
          Inline attachments
          <v-icon class="ml-1">
            {{ showInlineAttachments ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
          </v-icon>
        </v-btn>
        <div v-if="showInlineAttachments">
          <attachment-links-list :attachments="inlineAttachments">
            <template v-slot:attachment-append="{ attachment }">
              <v-menu
                v-if="orderHasPO || orderHasQuotes"
                v-model="attachmentMenus[attachment.id]"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ props: menu }">
                  <qtm-icon-btn
                    icon="mdi-chevron-down"
                    size="small"
                    v-bind="menu"
                  />
                </template>
                <v-list class="qtm-border">
                  <v-list-item v-if="orderHasPO" @click="copyToPO(internalOrder.pos[0], attachment)">
                    <v-list-item-title>Copy to PO</v-list-item-title>
                  </v-list-item>

                  <v-menu v-if="orderHasQuotes" open-on-hover :open-delay="0" location="end">
                    <template v-slot:activator="{ props: submenu }">
                      <v-list-item v-bind="submenu" @click="attachmentMenus[attachment.id] = true">
                        <v-list-item-title>
                          Copy to Quote
                          <v-icon class="ml-1" size="small" color="mid-light-grey">
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list class="qtm-border">
                      <v-list-item
                        v-for="quote in internalOrder.quotes"
                        :key="quote.id"
                        @click="copyToQuote(quote, attachment)"
                      >
                        <v-list-item-title>{{ quote.vendor.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list>
              </v-menu>
            </template>
          </attachment-links-list>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="float-right">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <qtm-btn
                v-bind="props"
                icon
                tertiary
                @click="$emit('reply', emailContent, 'single')"
              >
                <v-icon>mdi-reply</v-icon>
              </qtm-btn>
            </template>
            <span>Reply email</span>
          </v-tooltip>

          <v-menu v-model="emailMenu" content-class="qtm-border" offset-y>
            <template v-slot:activator="{ props }">
              <qtm-icon-btn v-bind="props" icon="mdi-dots-horizontal" />
            </template>
            <v-list class="text-mid-grey">
              <div v-for="item in emailMenuItems" :key="item.title">
                <v-list-item v-if="!item.submenu?.length" @click="item.onClick">
                  <template v-slot:prepend>
                    <v-icon>{{ item.icon }}</v-icon>
                  </template>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
                <v-menu v-else open-on-hover :open-delay="0" location="end">
                  <template v-slot:activator="{ props: submenu }">
                    <v-list-item v-bind="submenu" @click="emailMenu = true">
                      <template v-slot:prepend>
                        <v-icon>{{ item.icon }}</v-icon>
                      </template>
                      <v-list-item-title>
                        {{ item.title }}
                        <v-icon class="ml-1" size="small" color="mid-light-grey">
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list class="qtm-border">
                    <v-list-item
                      v-for="subitem in item.submenu"
                      :key="subitem.title"
                      @click="subitem.onClick"
                    >
                      <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-list>
          </v-menu>
          <email-classification :email="emailContent" class="d-block" />
        </div>
      </v-col>
    </v-row>
    <v-row class="rows email-content">
      <v-col class="inner-email-content text-black" cols="10" offset="1">
        <safe-html-renderer :html="emailContent.body" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AttachmentLinksList from '@/components/attachment-links-list.vue'
import EmailClassification from '@/components/emails/email-classification.vue'
import MoveEmail from '@/components/admin/emails/move-email.vue'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer.vue'
import { INLINE_IMAGE_CATEGORY } from '@/constants'

export default {
  name: 'view-email-detail',
  components: { AttachmentLinksList, EmailClassification, MoveEmail, SafeHtmlRenderer },
  props: {
    emailContent: {
      type: Object,
      required: true
    },
    canCreateInvoice: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: null
    },
  },
  emits: ['create-invoice', 'refresh', 'reply', 'update:email-content'],
  data() {
    return {
      emailOrder: null,
      showInlineAttachments: false,
      showMoveModal: false,
      showMoreActive: false,
      attachmentMenus: {},
      emailMenu: false,
    }
  },
  computed: {
    emailMenuItems() {
      const quotesSubMenu = []
      this.internalOrder?.quotes?.forEach(quote => {
        quotesSubMenu.push({
          title: quote.vendor.name,
          onClick: () => this.copyEmailPdfToQuote(quote),
        })
      })
      const items = [
        {
          title: 'Reply all',
          icon: 'mdi-reply-all',
          onClick: () => this.$emit('reply', this.emailContent, 'all'),
        },
        {
          title: 'Forward',
          icon: 'mdi-forward',
          onClick: () => this.$emit('reply', this.emailContent, 'forward'),
        },
        {
          title: 'Remove BOUNCED notification',
          icon: 'mdi-alert',
          onClick: this.markAsUnbounced,
          exclude: !this.isBounced,
        },
        {
          title: 'Move to another order',
          icon: 'mdi-folder-move',
          onClick: this.moveEmail,
        },
        {
          title: 'Mark as unread',
          icon: 'mdi-email',
          onClick: this.markAsUnread,
          exclude: !(this.isInbound && this.isRead),
        },
        {
          title: 'Mark as read',
          icon: 'mdi-email-open',
          onClick: this.markAsRead,
          exclude: !(this.isInbound && !this.isRead),
        },
        {
          title: 'Pin email to screen',
          icon: 'mdi-pin',
          onClick: () => this.showGlobalEmailViewer(this.emailContent),
        },
        {
          title: 'Create invoice',
          icon: 'mdi-receipt-text-plus',
          onClick: () => this.$emit('create-invoice', this.emailContent),
          exclude: !this.canCreateInvoice || !this.isInbound,
        },
        {
          title: 'Copy email PDF to PO',
          icon: 'mdi-clipboard-plus',
          onClick: this.copyEmailPdfToPo,
          exclude: !this.orderHasPO,
        },
        {
          title: 'Copy email PDF to Quote',
          icon: 'mdi-clipboard-plus',
          exclude: !this.orderHasQuotes,
          submenu: quotesSubMenu
        },
      ]

      return items.filter(item => !item.exclude)
    },
    inlineAttachments() {
      return this.emailContent.attachments?.filter(attachment => attachment.category === INLINE_IMAGE_CATEGORY) ?? []
    },
    internalOrder() {
      return this.emailOrder ?? this.order
    },
    isInbound() {
      return this.emailContent.email_type === 'inbound'
    },
    isRead() {
      return this.emailContent.tracking_status === 'opened'
    },
    isBounced() {
      return ['bounced', 'rejected'].includes(this.emailContent.tracking_status)
    },
    hasAttachments() {
      return this.emailContent && this.emailContent.attachments && this.emailContent.attachments.length
    },
    realAttachments() {
      return this.emailContent.attachments?.filter(attachment => attachment.category !== INLINE_IMAGE_CATEGORY) ?? []
    },
    orderHasPO() {
      return this.internalOrder?.pos?.length
    },
    orderHasQuotes() {
      return this.internalOrder?.quotes?.length
    },
    showMoreText() {
      return this.showMoreActive ? 'show less' : 'show more'
    },
  },
  mounted() {
    if (!this.order?.id && this.realAttachments.length) {
      this.fetchOrder(this.emailContent.rfq)
    }
  },
  methods: {
    ...mapMutations({
      removeBouncedEmail: 'admin/removeBouncedEmail',
      removeUnreadEmail: 'admin/removeUnreadEmail',
      showGlobalEmailViewer: 'admin/showGlobalEmailViewer',
    }),
    async fetchOrder(orderId) {
      if (!orderId) {
        return
      }

      try {
        this.emailOrder = await this.$api.v1.rfqs.get(orderId)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    async markAsRead() {
      try {
        await this.$api.v1.notifications.email.patch(this.emailContent.id, { tracking_status: 'opened' })
        await this.$api.v1.notifications.email.markAsRead(this.emailContent.id)
        this.emailContent.tracking_status = 'opened'
        this.removeUnreadEmail(this.emailContent)
        this.$emit('refresh')
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    markAsUnbounced() {
      this.markAsRead(this.emailContent)
      this.removeBouncedEmail(this.emailContent)
    },
    onEmailUpdate(email) {
      if (email.rfq) {
        this.fetchOrder(email.rfq)
      }

      this.$emit('update:email-content', email)
    },
    close() {
      this.showMoveModal = false
      this.$emit('refresh')
    },
    async copyEmailPdfToPo() {
      const copy = await this.copyEmailPdfToModel(this.internalOrder.pos[0].id, 'purchaseorder')

      if (copy) {
        this.internalOrder.pos[0].attachments.po_attachments.push(copy)
      }
    },
    async copyEmailPdfToQuote(quote) {
      const copy = await this.copyEmailPdfToModel(quote.id, 'quote')

      if (copy) {
        quote.attachments.push(copy)
      }
    },
    async copyEmailPdfToModel(id, model) {
      try {
        const data = { content_type: model, object_id: id }
        const copy = await this.$api.v1.notifications.email.saveAsPdf(this.emailContent.id, data)
        this.emailMenu = false
        this.$toast.success(`Email saved as PDF and copied to ${model}`)
        return copy
      }
      catch (error) {
        this.$toast.error('Email copy failed')
        this.$error.report(error)
      }
      return null
    },
    async copyToModel(id, model, attachment) {
      try {
        const copy = await this.$api.v1.attachments.copy(attachment.id, { content_type: model, object_id: id })
        this.attachmentMenus[attachment.id] = false
        this.$toast.success('Attachment copied')
        return copy
      }
      catch (error) {
        this.$toast.error('Attachment copy failed')
        this.$error.report(error)
      }
      return null
    },
    async copyToPO(po, attachment) {
      const copy = await this.copyToModel(po.id, 'purchaseorder', attachment)

      if (copy) {
        po.attachments.po_attachments.push(copy)
      }
    },
    async copyToQuote(quote, attachment) {
      const copy = await this.copyToModel(quote.id, 'quote', attachment)

      if (copy) {
        quote.attachments.push(copy)
      }
    },
    moveEmail() {
      this.showMoveModal = true
    },
    async markAsUnread() {
      try {
        await this.$api.v1.notifications.email.patch(this.emailContent.id, { tracking_status: 'delivered' })
        this.emailContent.tracking_status = 'delivered'
        this.$emit('refresh')
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    toggleShowMore() {
      this.showMoreActive = !this.showMoreActive
    },
    formatEmailList(emailList) {
      if (emailList) {
        if (emailList.includes(',')) {
          return emailList.replace(/,/g, ';\n')
        }
      }
      return emailList
    },
    openUrlsInNewTab(event) {
      const externalUrl = event.target.getAttribute('href')
      if (event.target.tagName === 'A' && externalUrl) {
        window.open(externalUrl)
      }
    }
  }
}
</script>
<style scoped>
  .email-data {
    color: rgb(var(--v-theme-mid-light-grey)) !important;
    padding: 0px 10px 10px 10px;
    font-size: 1rem;
  }
  .email-data label {
    color: rgb(var(--v-theme-mid-dark-grey)) !important;
    font-size: 1rem;
    padding-right: 0.4rem;
  }
  .email-content {
    padding: 10px 0;
    min-height: 300px;
  }
  .theme--dark .inner-email-content {
    background-color: white;
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    color: #333;
    padding: 20px;
  }
  .flexible {
    display: flex;
  }
  .truncated {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
  }
  .show-more {
    font-size: 1rem;
    color: rgb(var(--v-theme-interactive));
  }
  .show-more:hover {
    cursor: pointer;
  }
</style>
